//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import UserStakingStats from '~/views/UserStaking/UserStakingStats/UserStakingStats.vue';
import UserStakingLogs from '~/views/UserStaking/UserStakingLogs/UserStakingLogs.vue';

export default {
  components: {
    UserStakingStats,
    UserStakingLogs,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      // this.loadUserStakingStats(),
      this.loadUserStakingLogs(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('userStakingStats', {
      loadUserStakingStats: 'loadData',
    }),
    ...mapActions('userStakingLogs', {
      loadUserStakingLogs: 'loadData',
    }),
  },
};
