export default [
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyTitle,
    },
  },
  // {
  //   width: 11,
  //   header: {
  //     type: 'text',
  //     caption: 'Type',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'type',
  //   },
  // },
  // {
  //   width: 11,
  //   header: {
  //     type: 'text',
  //     caption: 'Status',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'status',
  //   },
  // },
  // {
  //   width: 11,
  //   header: {
  //     type: 'text',
  //     caption: 'Volume',
  //     sort: 'amount',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'volume',
  //   },
  // },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Perc',
    },
    cell: {
      type: 'slot',
      name: 'perc',
    },
  },
  {
    width: 53,
    header: {
      type: 'text',
      caption: 'Comment',
    },
    cell: {
      type: 'slot',
      name: 'comment',
    },
  },
];
