var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logs"},[_c('div',{staticClass:"logs__filters"},[_c('div',{staticClass:"d-flex"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.PARAMINING_LOGS,"command":_vm.requestData}})],1),_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"input":_vm.setPage}})],1),_c('div',{staticClass:"logs__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]}},{key:"perc",fn:function(ref){
var row = ref.row;
return [(row.perc)?[_vm._v(" "+_vm._s(row.perc)+"% ")]:_vm._e()]}},{key:"comment",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.comment)+" ")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"320px"}},[_vm._v(" "+_vm._s(row.comment)+" ")])])],1),(_vm.getAdminById(row.adminId))?_c('div',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.getAdminById(row.adminId).email)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }