export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Fix',
    },
    cell: {
      type: 'slot',
      name: 'fix',
    },
  },
  {
    width: 14,
    header: {
      type: 'text',
      caption: 'Staking',
    },
    cell: {
      type: 'slot',
      name: 'staking',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Balance',
    },
    cell: {
      type: 'slot',
      name: 'balance',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Income',
    },
    cell: {
      type: 'slot',
      name: 'income',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Daily income',
    },
    cell: {
      type: 'slot',
      name: 'dailyIncome',
    },
  },
];
