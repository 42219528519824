var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stats"},[_c('div',{staticClass:"stats__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),(!_vm.generalLoader)?_c('div',{staticClass:"d-flex mt-4 stats__additional"},[_c('div',{staticClass:"mr-6"},[_vm._v(" "+_vm._s(_vm.$t('Total income:'))+" "+_vm._s(_vm.toCurrencyFormat( _vm.additionalData.cumulativeTotalIncome, _vm.additionalData.cumulativeCurrencyTitle ))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]),_c('div',{staticClass:"mr-6"},[_vm._v(" "+_vm._s(_vm.$t('Monthly income:'))+" "+_vm._s(_vm.toCurrencyFormat( _vm.additionalData.cumulativeMonthlyIncome, _vm.additionalData.cumulativeCurrencyTitle ))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Daily income:'))+" "+_vm._s(_vm.toCurrencyFormat( _vm.additionalData.cumulativeDailyIncome, _vm.additionalData.cumulativeCurrencyTitle ))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")])]):_vm._e()],1),_c('div',{staticClass:"stats__table"},[_c('v-row',[_c('v-col',{staticClass:"stats__table-col"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"fixed-header":"","height":"527px","highlighted":_vm.currentCurrencyId,"to-highlight":function (e) { return e.currencyId; }},on:{"rowClick":_vm.setCurrentCurrency},scopedSlots:_vm._u([{key:"currency",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.currencyTitle)+" ")]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type)+" ")]}},{key:"fix",fn:function(ref){
var row = ref.row;
return [(row.percFix)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('Yes')))]):_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('No')))])]}},{key:"staking",fn:function(ref){
var row = ref.row;
return [(row.percFix)?_c('span',[_vm._v(_vm._s(row.perc)+"%")]):_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(row.perc)+"%")])]}},{key:"balance",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.balance)+" ")]}},{key:"income",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.total)+" ")]}},{key:"dailyIncome",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.day)+" ")]}}])}),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)],1),_c('v-col',{staticClass:"stats__edit-col"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Settings'))+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center pb-4"},[_c('div',{staticStyle:{"width":"180px"}},[_c('v-text-field',{attrs:{"dense":"","disabled":!_vm.currentCurrency || !_vm.changeStaking.input.fix,"hide-details":"","label":_vm.$t('Staking:'),"outlined":"","placeholder":" ","suffix":"%"},model:{value:(_vm.changeStaking.input.perc),callback:function ($$v) {_vm.$set(_vm.changeStaking.input, "perc", $$v)},expression:"changeStaking.input.perc"}})],1),_c('div',{staticClass:"ml-6"},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"inset":"","label":_vm.$t('Fix percent'),"hide-details":""},model:{value:(_vm.changeStaking.input.fix),callback:function ($$v) {_vm.$set(_vm.changeStaking.input, "fix", $$v)},expression:"changeStaking.input.fix"}})],1)]),_c('div',[_c('v-textarea',{attrs:{"dense":"","disabled":!_vm.currentCurrency,"label":_vm.$t('Comment:'),"outlined":"","placeholder":" ","rows":"3"},model:{value:(_vm.changeStaking.input.comment),callback:function ($$v) {_vm.$set(_vm.changeStaking.input, "comment", $$v)},expression:"changeStaking.input.comment"}})],1),_c('div',[_c('v-btn',{attrs:{"color":"error","disabled":!_vm.isApplyPossibly,"outlined":""},on:{"click":_vm.applyUpdateParaminingPerc}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")])],1)])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }