//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './UserStakingStatsFilters.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      currentCurrencyId: 0,
      changeStaking: {
        input: {
          perc: '',
          comment: '',
          fix: false,
        },
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    ...mapState('user', ['user']),
    ...mapState('userStakingStats', ['page', 'pages', 'data', 'sort', 'additionalData']),

    currentCurrency() {
      const { currentCurrencyId, data } = this;
      return data.find((e) => e.currencyId === currentCurrencyId);
    },

    userId() {
      const { id } = this.user;
      return id;
    },

    isApplyPossibly() {
      const { currentCurrency } = this;
      const { perc, comment } = this.changeStaking.input;
      return (
        currentCurrency
        && perc !== ''
        && comment !== ''
      );
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('userStakingStats', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      updateParaminingPercAction: 'updateParaminingPerc',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    setCurrentCurrency({ currencyId }) {
      this.currentCurrencyId = currencyId;

      const { currentCurrency } = this;
      this.changeStaking.input.perc = currentCurrency.perc;
      this.changeStaking.input.fix = currentCurrency.percFix;
    },

    applyUpdateParaminingPerc() {
      const { currentCurrency, userId } = this;
      if (!currentCurrency) return;

      this.confirmAction({
        title: 'Are you sure you want to change staking percent?',
        callback: async () => {
          const { perc, comment, fix } = this.changeStaking.input;
          const payload = {
            currencyId: currentCurrency.currencyId,
            userIdList: [userId],
            perc,
            comment,
            fix,
          };

          this.setGeneralProgress(true);
          try {
            await this.updateParaminingPercAction(payload);
            this.loader = true;
            await this.loadData({});
            this.loader = false;
            this.setSuccessNotification('Staking percent updated');
            this.changeStaking.input.comment = '';
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
